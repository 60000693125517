import React from "react";

const AboutTextTwo = () => {
  return (
    <div className="w-full max-w-screen-xl mx-auto pt-10 lg:pt-16 px-4 sm:px-6 md:px-0 lg:px-0">
       <h1
          className="text-3xl sm:text-3xl md:text-4xl lg:text-6xl text-center"
          style={{ fontFamily: "Butler, serif" }}
        >
        SPEAKER, INVESTOR AND LEGACY COACH
      </h1>
    </div>
  );
};

export default AboutTextTwo;
