import React from 'react'
import WorkWithGustavPageTwoTop from '../WorkWithGustav/WorkWithGustavPageTwoTop'
import ProgramIncludes from './ProgramIncludes'

const WorkWithGustavPageTwo = () => {
  return (
    <><WorkWithGustavPageTwoTop /><ProgramIncludes /></>
  )
}

export default WorkWithGustavPageTwo
