import React from "react";
import ContactHero from "./ContactHero";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactForm />
    </>
  );
};

export default Contact;
