import React from "react";
import AboutHero from "./AboutHero";
import AboutGustav from "./AboutGustav";
import AboutTextTwo from "./AboutTextTwo";
import CommunityComponent from "../Home/CommunityComponent ";
import GustavAction from "../Home/GustavAction";
import SuccessStories from "../Home/SuccessStories";

const About = () => {
  return (
    <div>
      <AboutHero />
      <AboutTextTwo />
      <AboutGustav />
      <GustavAction />
      <SuccessStories />
      <CommunityComponent
        title="WORK WITH GUSTAV"
        subtitle="Gustav works with clients to intentionally create their ideal ecosystem and design how they will be remembered."
      />
    </div>
  );
};

export default About;
